import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import Vant from 'vant';
import 'vant/lib/index.css';
import { Notify } from 'vant';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import { Toast } from 'vant';

Vue.use(Toast);
Vue.use(Vant);
Vue.use(Notify);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
