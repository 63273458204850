<template>
  <div>
    <div class="main">
      <div class="mainbox">
        <div class="main-left">
          <img src="../assets/img/icon13.svg" class="iconLogo" />
          <p>
            Android Tablet <br />
            Developer Preview Program
          </p>
        </div>
        <div class="main-right">
          <ul>
            <li @click="routerPush('pro13')" :class="boderFlag == '1' ? 'boderFlag':''">Android 13</li>
            <li @click="routerPush('pro14')" :class="boderFlag == '2' ? 'boderFlag':''">Android 14</li>
            <li @click="routerPush('pro15')" :class="boderFlag == '3' ? 'boderFlag':''">Android 15</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="main-pro">
      <router-view />
    </div>
  </div>
</template>

<script>
import { onUpdated } from 'vue';

export default {
  data(){
    return{
      boderFlag:'3',
    }
  },

  created() {
    console.log("beforeUpdate this.$route.path ",this.$route.path);
    if(this.$route.path.indexOf('roid13') != -1){
      this.boderFlag = '1'
    } else if(this.$route.path.indexOf('roid14') != -1){
      this.boderFlag = '2'
    } else if(this.$route.path.indexOf('roid15') != -1){
      this.boderFlag = '3'
    }
  },

  methods:{
    routerPush(data){
      if(data == 'pro13' && this.$route.path.indexOf('android13') == -1){
        this.$router.push('/android13')
        this.boderFlag = '1'
      }else if(data == 'pro14' && this.$route.path.indexOf('android14') == -1){
        this.$router.push('/android14')
        this.boderFlag = '2'
      }else if(data == 'pro15' && this.$route.path.indexOf('android15') == -1){
        this.$router.push('/android15')
        this.boderFlag = '3'
      }
    }
  }
};
</script>

<style scoped>
.iconLogo{
  width: 180px;
  height: 60px;
  margin-right: 13px;
  margin-top: 18px;
}
.main {
  height: 100px;
  background: #fff;
  display: flex;
  justify-content: space-between;
}
.main-left{
  display: flex;
}
.main-left p{
  font-size: 24px;
}
.mainbox {
  width: 1342px;
  /* width: 63%; */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.main-pro {
  width: 1342px;
  margin: 0 auto;
}
.main-right ul {
  line-height: 95px;
  display: flex;
}
.main-right ul li {
  margin-left: 50px;
  cursor: pointer;
  font-size: 30px;
}
.main-right ul li:hover {
  margin-left: 50px;
  box-sizing: border-box;
  border-bottom: 5px solid #999;
  list-style: none;
}
.main-right ul .boderFlag{
  margin-left: 50px;
  box-sizing: border-box;
  list-style: none;
  border-bottom: 7px solid #c12f2b;
}
</style>